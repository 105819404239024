<script setup lang="ts">
import { StandIcon } from '@stpkg/stand-vue'
import type { IconName } from '@stpkg/stand-vue/dist/types/types/icon'
import { computed } from 'vue'

const { type = 'outlined', size = 'medium', as = 'button', disabled = false } = defineProps<{
  type?: 'fill' | 'outlined'
  size?: 'small' | 'medium' | 'large'
  as?: 'button' | 'div'
  icon: IconName
  disabled?: boolean
}>()

defineEmits<{
  click: []
}>()

const sizeMap = {
  small: { class: 'size-4', icon: 16 },
  medium: { class: 'size-5', icon: 20 },
  large: { class: 'size-6', icon: 24 },
}

const buttonSizeClass = computed(() => sizeMap[size].class)
const iconSize = computed(() => sizeMap[size].icon)

const typeClassMap = {
  fill: 'bg-surface-hover-inverse',
  outlined: 'border border-solid border-gray-50 hover:bg-gray-50',
  default: 'bg-transparent',
}

const disabledClass = 'border-none bg-surface-fill-gray'

const typeClasses = computed(() =>
  disabled ? disabledClass : typeClassMap[type] ?? typeClassMap.default,
)

const iconColorMap = {
  fill: '#fff',
  outlined: '#737368',
  default: '#737368',
  disabled: '#D0D0CC',
}

const iconColorCode = computed(() =>
  disabled ? iconColorMap.disabled : iconColorMap[type] ?? iconColorMap.default,
)
</script>

<template>
  <component
    :is="as"
    :disabled="disabled"
    class="flex items-center justify-center rounded-full outline-none hover:ease-[ease] focus-visible:ring-2 focus-visible:ring-blue-700 active:scale-95 active:duration-100"
    :class="[buttonSizeClass, typeClasses]"
    @click="$emit('click')"
  >
    <StandIcon
      :width="iconSize"
      :height="iconSize"
      :icon="icon"
      :color-code="iconColorCode"
    />
  </component>
</template>
